import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Obfuscate from 'react-obfuscate';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { ImPhone } from 'react-icons/im';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Sarah from '../components/Sarah';
import Florian from '../components/Florian';
import ogImage from '../assets/images/sarah-florian-such-portrait-both.jpg';

const HomePageStyles = styled.main`
  display: flex;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ContentStyles = styled.article`
  flex-basis: 660px;
  flex-grow: 2;
  border-right: 1px solid #e8e8e8;
  padding-top: 4rem;
  justify-content: flex-start;
  > * {
    margin-left: 4rem;
    margin-right: 3rem;
  }

  .services {
    padding-left: 0.8em;
    li:before {
      content: '... ';
      margin-left: -0.8em;
    }
  }

  .button {
    margin-bottom: 0.5rem;
  }

  @media (min-width: 768px) {
    .mobileOnly {
      display: none;
    }
  }
`;

const SarahStyles = styled(Sarah)`
  margin: 3rem 0 0 0;
`;
const FlorianStyles = styled(Florian)`
  margin: 3rem 0 0 0;
`;

export default function HomePage() {
  return (
    <>
      <GatsbySeo
        title="Psychotherapie und Paartherapie in Ravensburg - Sarah und Florian Such"
        titleTemplate="%s"
        description="In unserer Praxis in Ravensburg unterstützen wir Sie bei psychischen Problemen und
            Krisen sowie in schwierigen Lebenssituationen. Gerne begleiten wir auch Sie in Richtung
            mehr Lebensqualität."
        openGraph={{
          title: 'Psychotherapie Ravensburg - Sarah und Florian Such',
          images: [
            {
              url: ogImage,
              width: 1000,
              height: 667,
            },
          ],
        }}
      />
      <Header />
      <HomePageStyles>
        <ContentStyles>
          <h2>Herzlich Willkommen auf unserer Website</h2>
          <p>
            In unserer Praxis in Ravensburg bieten wir therapeutische
            Unterstützung für Einzelne und Paare an, um wieder zu mehr
            Lebensqualität zu finden. Manchmal häufen sich im Leben emotionale,
            körperliche oder seelische Belastungen. Bestimmte Lebenssituationen
            oder -ereignisse können dazu führen, dass man sich nicht mehr zu
            helfen weiß. Man erkennt sich selbst kaum wieder oder fühlt sich
            blockiert.
          </p>
          <p>
            Für Paare, die sich in einer Krise befinden oder etwas an Ihrer
            Partnerschaft verändern möchten, bieten wir auch{' '}
            <Link to="/paartherapie/">Paartherapie bzw. –beratung</Link> an.
            Gerne klären wir im ersten Kontakt, ob eine Paartherapie bei Ihnen
            sinnvoll ist.{' '}
            <Link to="/paartherapie/">
              Mehr über unser Paartherapie-Angebot erfahren Sie hier.
            </Link>
          </p>
          <p>
            Immer geht es darum in einer vertrauensvollen und wertschätzenden
            Atmosphäre Ihre Problematik besser zu verstehen, um darauf aufbauend
            Lösungsmöglichkeiten und Veränderungswege zu erarbeiten. Sie können
            sich gerne an uns wenden, wenn Sie sich professionelle Unterstützung
            wünschen.
          </p>
          <Obfuscate
            tel="00497519770715"
            className="button mobileOnly"
            obfuscateChildren={false}
            linkText="https://psychotherapieravensburg.de/datenschutz/"
          >
            <span style={{ display: 'inline-block' }}>
              <ImPhone style={{ marginBottom: '-1px', height: '19px' }} /> 0751
              / 977 707 15
            </span>
          </Obfuscate>
          <p className="mobileOnly">
            <a href="#emailButton">Adresse, E-Mail, Sprechzeiten</a>
          </p>
          <p>
            <strong>
              Wenn Ihr Anliegen eine Psychotherapie erfordert, wird unsere
              Leistung von den Krankenkassen (gesetzlich und privat) übernommen.
              Paartherapien sind eine Selbstzahlerleistung, da diese nicht von
              den Krankenkassen übernommen werden.
            </strong>
          </p>
          <p>
            <strong>
              Teilweise können unsere Leistungen auch online per
              Videositzung/-telefonat durchgeführt werden. Dafür müssen wir
              vorab klären, ob das in Ihrem Fall möglich ist.
            </strong>
          </p>
          <p>
            <strong>Sie können sich auch an uns wenden, wenn Sie...</strong>
          </p>
          <ul className="services">
            <li>
              sich begleitende Unterstützung bei der Klärung und Bewältigung von
              Partnerschaftskonflikten/-krisen wünschen
            </li>
            <li>
              Ihre berufliche bzw. private Situation reflektieren und verändern
              wollen
            </li>
            <li>
              sich weiterentwickeln oder sich selbst besser kennen lernen
              möchten
            </li>
          </ul>
          <p>
            Paartherapie/-beratung und Coaching sind keine Kassenleistungen und
            müssen selbst finanziert werden. Wir beantworten gerne alle Fragen
            zum Rahmen und möglichen Umfang einer potentiellen Zusammenarbeit im
            ersten Kontakt.
          </p>
          <h2>Lehrpraxis</h2>
          <p>
            Wir sind als eine psychotherapeutische Lehrpraxis anerkannt. Bei uns
            in der Praxis gibt es auch ein Psychotherapie-Angebot durch
            Kolleg*Innen bzw. Psycholog*Innen, die sich in einer
            fortgeschrittenen Phase der mehrjährigen, post-gradualen
            Weiterbildung zur Psychologischen Psychotherapeut*In befinden.
            Dieses Angebot ist ebenfalls eine von der Krankenkasse finanzierte,
            Leitlinien gerechte Psychotherapie. Zur Qualitätssicherung werden
            diese Behandlungen entsprechend der Psychotherapie-Leitlinine mit
            begleitender Supervision durchgeführt. Das heißt, dass ein*e
            erfahrene*r Behandler, die/der Supervisor*In, im Hintergrund, ohne
            direkten Klientenkontakt, die psychotherapeutische Arbeit begleitet.
            Wir freuen uns durch die Unterstützung der Kolleg*Innen unsere
            Behandlungskapazitäten erweitern und manchmal zeitnäher
            Therapieplätze in unserer Praxis anbieten zu können. Bei einer
            Anmeldung während der Telefonzeiten können wir Ihnen gerne
            weiterführende Informationen zu dieser Option geben.
          </p>

          <h2>Unsere Qualifikationen</h2>
          <SarahStyles key="sarah" />
          <FlorianStyles key="florian" />
        </ContentStyles>
        <Sidebar />
      </HomePageStyles>
    </>
  );
}
