import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const PersonStyles = styled.div`
  display: flex;

  div:nth-child(2) {
    margin-left: 4.4rem;
    transition: all 0.3s ease-in-out;
  }

  button {
    display: none;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    button {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 1.5rem 1.5rem;
    }

    div:nth-child(2) {
      margin-top: 2rem;
      margin-right: 3rem;
      max-height: 0;
      overflow: hidden;
      h3 {
        display: none;
      }
    }

    div.show {
      max-height: 600px;
    }
  }
`;

const PersonImageWrapper = styled.div`
  min-width: 200px;
  flex-basis: 200px;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 50%;

  p {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0.7rem 0 0.7rem 1.8rem;
    width: calc(100% - 1.8rem);
    color: white;
    background: rgba(177, 177, 177, 0.8);
    backgdrop-filter: blur(4px);
    @media (min-width: 768px) {
      display: none;
    }
  }
  div {
    position: absolute;
    top: -30%;
    left: -5%;
    width: 130%;
    height: 130%;

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
      left: 0;
      top: -99%;
    }
  }
`;

export default function Sarah({ className, children }) {
  const [isOpen, setOpen] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "florian-such-portrait.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <PersonStyles className={className}>
      <PersonImageWrapper>
        <Img
          fluid={data.file.childImageSharp.fluid}
          style={{ position: 'absolute', overflow: 'initial' }}
          imgStyle={{
            objectPosition: 'top center',
            minHeight: '100%',
            height: 'auto',
            width: '100%',
          }}
          alt="Florian Such"
        />
        <p>
          Dipl.-Psych. <strong>Florian Such</strong>
        </p>
        <button type="button" onClick={() => setOpen(!isOpen)}>
          {!isOpen ? 'Lesen' : 'X'}
        </button>
      </PersonImageWrapper>
      <div className={isOpen ? 'show' : ''}>
        <h3>Dipl.-Psych. Florian Such</h3>
        <ul>
          <li>
            Diplom-Psychologe mit Spezialisierung in Klinischer Psychologie
            sowie Arbeits- und Organisationspsychologie
          </li>
          <li>
            Psychologischer Psychotherapeut mit Fachkundenachweis in den
            Richtlinienverfahren Systemische Therapie und Verhaltenstherapie
          </li>
          <li>
            Systemischer Berater und Therapeut, Systemische Gesellschaft (SG)
            und Wieslocher Institut für systemische Lösungen (WISL)
          </li>
          <li>
            Mehrjährige Erfahrung in stationärer und ambulanter Psychotherapie,
            sowie in Familien- und Paartherapie/-beratung
          </li>
          <li>
            Lehraufträge für Psychotherapie am APB-Zentrum für Psychotherapie in
            Konstanz und am Bodensee Institut für Systemische Therapie und
            Beratung in Radolfzell
          </li>
          <li>
            Fortbildungen in Hypnotherapie, Familien-/Strukturaufstellungen,
            Ego-State-Therapie und Schematherapie
          </li>
          <li>
            Eingetragen im Arztregister der Kassenärztlichen Vereinigung
            Baden-Württemberg (KV BW)
          </li>
          <li>
            Mitglied der Landespsychotherapeutenkammer Baden-Württemberg (LPK
            BW)
          </li>
          <li>
            Mitglied der Deutschen Gesellschaft für Verhaltenstherapie (DGVT)
          </li>
          <li>Mitglied der Systemischen Gesellschaft (SG)</li>
        </ul>
      </div>
    </PersonStyles>
  );
}
